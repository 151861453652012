<template>
  <div class="setting-page _font-weight__600">
    <div class="setting-page__title _font-size__heading3">Setting</div>

    <div class="form-box">
      <div class="form-box__label">Camera Setting</div>
      <el-form label-width="250px" label-position="left">
        <el-form-item :label="item.label" v-for="item in formView" :key="item.key">
          <div class="form-box__content _flex">
            <div class="form-box__content__label _font-size__sub__heading">Capture time</div>
            <el-select v-model="formData[item.key]">
              <el-option
                v-for="each in options"
                :key="each.dictLabel"
                :label="each.dictLabel"
                :value="Number(each.dictValue)"
              ></el-option>
            </el-select>
          </div>
        </el-form-item>
      </el-form>

      <div class="form-box__buttons">
        <el-button type="primary" plain @click="handleCancel">Cancel</el-button>
        <el-button type="primary" @click="handleSave">Save</el-button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scope>
.setting-page {
  &__title {
    margin-bottom: 40px;
  }

  .form-box {
    &__label {
      font-size: 16px;
      margin-bottom: 20px;
    }

    .form-box__content {
      &__label {
        margin-right: 20px;
      }
      .el-input {
        width: 150px;

        &__inner {
          height: 36px;
          border-color: var(--color-black);
          color: var(--color-black);

          &::placeholder {
            color: var(--color-black);
          }
        }
      }

      & .el-select__caret.el-icon-arrow-up::before {
        content: '\e78f';
        color: var(--color-black);
      }
    }

    &__buttons {
      margin-top: 80px;
      .el-button {
        width: 160px;
        font-weight: 600;
        font-size: 14px;
      }
      .el-button + .el-button {
        margin-left: 45px;
      }
    }
  }
}
</style>

<script>
import { settingGet, settingUpdate } from '@/api/setting'
import { optionsFun } from '@/utils'

let defaultData = {}
export default {
  data() {
    return {
      formView: [
        {
          label: 'Ground Camera',
          key: 'groundCamera'
        },
        {
          label: 'Tilt Sensor',
          key: 'tiltSensor'
        },
        {
          label: 'Tower Camera',
          key: 'towerCamera'
        }
      ],
      formData: {
        groundCamera: '',
        tiltSensor: '',
        towerCamera: ''
      },

      options: optionsFun({
        0: '4 hours',
        1: '6 hours',
        2: '12 hours',
        3: '1 day',
        4: '7 days',
        5: '1 month'
      })
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      settingGet().then((res) => {
        this.formData = res.result
        defaultData = { ...res.result }
      })
    },
    handleCancel() {
      this.formData = { ...defaultData }
      console.log(this.formData, defaultData)
    },
    handleSave() {
      settingUpdate(this.formData).then(() => {
        this.$message.success('保存成功！')
      })
    }
  }
}
</script>
